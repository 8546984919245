import Bulb from "../assets/images/bulb.svg";
import Plug from "../assets/images/plug.svg";
import Tower from "../assets/images/tower.svg";
import Project1 from "../assets/images/project-1.png";
import Project2 from "../assets/images/project-2.png";
import Project3 from "../assets/images/project-3.png";
import Project4 from "../assets/images/project-4.png";
import Project5 from "../assets/images/project-5.png";
import Project6 from "../assets/images/project-6.png";
import Team1 from "../assets/images/team-1.png";
import Team2 from "../assets/images/team-2.png";
import Team3 from "../assets/images/team-3.png";
import Review1 from "../assets/images/review-1.png";
import Review2 from "../assets/images/review-2.png";
import Review3 from "../assets/images/review-3.png";
import Blog1 from "../assets/images/blog-1.png";
import Blog2 from "../assets/images/blog-2.png";
import Blog3 from "../assets/images/blog-3.png";

export const SERVICES = [
	{
		icon: Bulb,
		title: "Lighting Upgrades",
		description:
			"Lorem ipsum doller sit amet  popularised in we athe 1960s with the release of Letraset sheets containing Lorem Ipsum.",
	},
	{
		icon: Plug,
		title: "Electrical Testing",
		description:
			"Lorem ipsum doller sit amet  popularised in we athe 1960s with the release of Letraset sheets containing Lorem Ipsum.",
	},
	{
		icon: Tower,
		title: "Electric Installation",
		description:
			"Lorem ipsum doller sit amet  popularised in we athe 1960s with the release of Letraset sheets containing Lorem Ipsum.",
	},
];

export const PROJECTS = [
	{
		img: Project1,
		title: "Installing new electrical conduit in a renovation project.",
		category: "Wiring Conduit",
	},
	{
		img: Project2,
		title: "Fixing the wires on a ceiling light fixure.",
		category: "Light Fixture Repair",
	},
	{
		img: Project3,
		title: "Wires in an outlet receptacle.",
		category: "Receptacle Wiring",
	},
	{
		img: Project4,
		title: "Attaching wires to an electrical outlet.",
		category: "Outlet Box",
	},
	{
		img: Project5,
		title: "Electrical flow of a regular wall outlet.",
		category: "Outlet Testing",
	},
	{
		img: Project6,
		title: "Attaching the wires to a new electrical system.",
		category: "Panel Box Installation",
	},
];

export const TEAM = [
	{
		img: Team1,
		titie: "Erik Wiliamson",
		designation: "CEO & Founder",
	},
	{
		img: Team2,
		titie: "David borison",
		designation: "Sr. Electrician",
	},
	{
		img: Team3,
		titie: "Methue Martin",
		designation: "Operation Manager",
	},
];

export const REVIEWS = [
	{
		img: Review1,
		title: "John Waxley",
		designation: "Accountant",
		reviewTxt:
			"There are many variations of passages they of Lorem Ipsum available, but the majority have suffered alteration also we  in some form.",
	},
	{
		img: Review2,
		title: "Paul Youngter",
		designation: "Sport Person",
		reviewTxt:
			"There are many variations of passages they of Lorem Ipsum available, but the majority have suffered alteration also we  in some form.",
	},
	{
		img: Review3,
		title: "Kate Breitenberg",
		designation: "IT Professional",
		reviewTxt:
			"There are many variations of passages they of Lorem Ipsum available, but the majority have suffered alteration also we  in some form.",
	},
];

export const BLOGS = [
	{
		img: Blog1,
		title: "First Monitoring System for Robot e-chains",
		description:
			"Lorem ipsum doller sit amet  popularised in we athe 1960s with the release of Letraset sheets containing Lorem Ipsum.",
		author: "John Doe",
		publishDate: "05 APR, 2023",
	},
	{
		img: Blog2,
		title: "How to Read Your Commercial Electricity Bill",
		description:
			"Lorem ipsum doller sit amet  popularised in we athe 1960s with the release of Letraset sheets containing Lorem Ipsum.",
		author: "John Doe",
		publishDate: "05 APR, 2023",
	},
	{
		img: Blog3,
		title: "Should I Install a Whole House Generator?",
		description:
			"Lorem ipsum doller sit amet  popularised in we athe 1960s with the release of Letraset sheets containing Lorem Ipsum.",
		author: "John Doe",
		publishDate: "05 APR, 2023",
	},
];

export const FAQ_DATA = [
	{
		id: 1,
		que: "What does NO and NC in contactor stands for??",
		ans: "Lorem Ipsum is simply dummy text of the Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Lorem Ipsum is not random text. printing typesetting industry.",
	},
	{
		id: 2,
		que: "If a circuit breaker trips, is this dangerous?",
		ans: "Lorem Ipsum is simply dummy text of the Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Lorem Ipsum is not random text. printing typesetting industry.",
	},
	{
		id: 3,
		que: "Do you have references available for Public Observation?",
		ans: "Lorem Ipsum is simply dummy text of the Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Lorem Ipsum is not random text. printing typesetting industry.",
	},
	{
		id: 4,
		que: "What are the services Apollo Power Systems cater to for Industrial Electrification?",
		ans: "Lorem Ipsum is simply dummy text of the Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Lorem Ipsum is not random text. printing typesetting industry.",
	},
	{
		id: 5,
		que: "What types of Electrical Licenses can an electrician apply for?",
		ans: "Lorem Ipsum is simply dummy text of the Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Lorem Ipsum is not random text. printing typesetting industry.",
	},
	{
		id: 6,
		que: "What are the common Electrical Acronyms and what do they stand for?",
		ans: "Lorem Ipsum is simply dummy text of the Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Lorem Ipsum is not random text. printing typesetting industry.",
	},
	{
		id: 7,
		que: "How will Apollo Power Systems conduct installation of the new wiring in a new building?",
		ans: "Lorem Ipsum is simply dummy text of the Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Lorem Ipsum is not random text. printing typesetting industry.",
	},
	{
		id: 8,
		que: "How Do I Know if My Electrical Outlet Is Burning?",
		ans: "Lorem Ipsum is simply dummy text of the Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Lorem Ipsum is not random text. printing typesetting industry.",
	},
	{
		id: 9,
		que: "How Can I Tell What Is Tripping My Circuit Breaker?",
		ans: "Lorem Ipsum is simply dummy text of the Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Lorem Ipsum is not random text. printing typesetting industry.",
	},
	{
		id: 1,
		que: "What is the difference between 110 and 220 volts?",
		ans: "Lorem Ipsum is simply dummy text of the Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Lorem Ipsum is not random text. printing typesetting industry.",
	},
];

export const PRICING_PLANS = [
	{
		title: "Personal",
		price: "65.00",
	},
	{
		title: "Home Plan",
		price: "85.00",
		category: "Popular",
	},
	{
		title: "Industrial Plan",
		price: "110.00",
		category: "Hot",
	},
	{
		title: "Standard Plan",
		price: "135.00",
		category: "Hot",
	},
	{
		title: "Premium Plan",
		price: "170.00",
		category: "Hot",
	},
	{
		title: "Business Plan",
		price: "199.00",
		category: "Hot",
	},
];
