import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import ReviewSection from "../sections/ReviewSection";
import PartnerSection from "../sections/PartnerSection";
import Accordian from "../components/Accordian";
import { FAQ_DATA } from "../constants";

const FAQ = () => {
	return (
		<Layout>
			<SubHeroSection title="Frequently Asked Questions" />
			<div className="relative bg-troo-light text-troo-tertiary w-full flex flex-col items-center">
				<div className="main-container-big hs-accordion-group w-full py-[100px] flex flex-col items-center gap-6">
					{FAQ_DATA.map((data, i) => {
						return <Accordian key={i} data={data} isActive={i === 0} />;
					})}
				</div>
			</div>
			<ReviewSection onlyForm />
			<PartnerSection />
		</Layout>
	);
};

export default FAQ;
