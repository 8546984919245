import React from "react";
import { FiPlus } from "react-icons/fi";
import { motion } from "framer-motion";

const ProjectCard = ({ data }) => {
	return (
		<motion.div initial={{ y: 20, opacity: 0 }}
			whileInView={{ y: 0, opacity: 1 }}
			transition={{
				type: "spring",
				stiffness: 100,
			}} className="w-full h-full relative overflow-hidden rounded-lg">
			<img
				src={data?.img}
				alt={data?.title}
				className="w-full h-full object-cover"
			/>
			<div className="absolute top-0 left-0 w-full h-full bg-troo-primary bg-opacity-10 flex flex-col items-center justify-end p-6">
				<div className="w-full bg-troo-light p-5 rounded-lg flex flex-col items-start gap-4 relative">
					<div className="p-3 bg-troo-secondary rounded-lg text-troo-primary absolute right-5 -top-5 aspect-square">
						<FiPlus />
					</div>
					<h5>{data?.title}</h5>
					<p>{data?.category}</p>
				</div>
			</div>
		</motion.div>
	);
};

export default ProjectCard;
