import React from "react";

const Input = ({ placeholder, icon }) => {
	return (
		<div class="relative">
			<input
				type="text"
				className="py-4 px-4 pe-11 w-full border bg-transparent border-troo-tertiary border-opacity-20 rounded-lg shadow-sm text-sm"
				placeholder={placeholder}
			/>
			<div className="absolute inset-y-0 text-troo-tertiary text-opacity-50 end-0 flex items-center pointer-events-none z-20 pe-4">
				{icon}
			</div>
		</div>
	);
};

export default Input;
