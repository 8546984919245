import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import PartnerSection from "../sections/PartnerSection";
import Input from "../components/Input";
import Heading from "../components/Heading";
import { HiOutlineLocationMarker, HiOutlineMailOpen } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";

const ContactUs = () => {
	return (
		<Layout>
			<SubHeroSection title="Get In Touch With Us" />
			<div className="relative bg-troo-primary-light text-troo-primary w-full flex flex-col items-center">
				<div className="main-container-big w-full py-[100px] flex flex-col items-center text-troo-primary bg-transparent gap-7">
					<div
						className={`w-full grid grid-cols-1 lg:grid-cols-3 gap-5 items-center overflow-hidden`}
					>
						<div className="w-full h-full rounded-xl bg-troo-primary text-troo-light p-10 flex flex-col items-start gap-5">
							<Heading
								isStart
								headline="Get a free quotes"
								title={
									<>
										Here Is our Contact
										<br /> Information
									</>
								}
							/>
							<p>
								There are many variations of passages they of Lorem Ipsum
								available.
							</p>
							<div className="flex item-center gap-3 text-troo-light">
								<div className="text-3xl p-3 aspect-square rounded-md bg-troo-secondary flex items-center justify-center">
									<HiOutlineMailOpen />
								</div>
								<div className="flex flex-col items-start">
									<p className="text-xl font-bold">Email Me</p>
									<p>contact@troothemes.com</p>
								</div>
							</div>
							<div className="flex item-center gap-3 text-troo-light">
								<div className="text-3xl p-3 aspect-square rounded-md bg-troo-secondary flex items-center justify-center">
									<BsTelephone />
								</div>
								<div className="flex flex-col items-start">
									<p className="text-xl font-bold">Call Us on</p>
									<p>+44 123 456 7890</p>
								</div>
							</div>
							<div className="flex item-center gap-3 text-troo-light">
								<div className="text-3xl p-3 aspect-square rounded-md bg-troo-secondary flex items-center justify-center">
									<HiOutlineLocationMarker />
								</div>
								<div className="flex flex-col items-start">
									<p className="text-xl font-bold">Office Location</p>
									<p>4516 School Street Danbury, CT, Canada</p>
								</div>
							</div>
							<div className="flex item-center gap-3 text-troo-light">
								<div className="text-3xl p-3 aspect-square rounded-md bg-troo-secondary flex items-center justify-center">
									<HiOutlineChatBubbleLeftRight />
								</div>
								<div className="flex flex-col items-start">
									<p className="text-xl font-bold">WhatsApp No.</p>
									<p>+44 987 654 3210</p>
								</div>
							</div>
						</div>
						<div className="w-full h-full lg:col-span-2 p-10 flex flex-col items-start bg-troo-light gap-5 rounded-xl">
							<Heading
								isStart
								headline="Let’s Collaborate"
								title={
									<>
										Tell Us About Your Problem
										<br /> We are Ready to Solve
									</>
								}
							/>
							<div className="w-full flex flex-col items-start gap-3">
								<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3">
									<Input
										placeholder="Name"
										icon={
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-6 h-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
												/>
											</svg>
										}
									/>
									<Input
										placeholder="Email"
										icon={
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-6 h-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
												/>
											</svg>
										}
									/>
									<Input
										placeholder="Phone"
										icon={
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-6 h-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
												/>
											</svg>
										}
									/>
									<Input
										placeholder="Company Name"
										icon={
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-6 h-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
												/>
											</svg>
										}
									/>
								</div>
								<select className="py-4 px-4 pe-11 w-full border bg-transparent border-troo-tertiary border-opacity-20 rounded-lg shadow-sm text-sm">
									<option selected>option 1</option>
								</select>
								<div class="relative w-full">
									<textarea
										className="py-4 px-4 pe-11 w-full border bg-transparent border-troo-tertiary border-opacity-20 rounded-lg shadow-sm text-sm"
										rows={4}
										placeholder="Your Message"
									/>
									<div className="absolute inset-y-0 text-troo-tertiary text-opacity-50 end-0 flex items-start pt-4 pointer-events-none z-20 pe-4">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth={1.5}
											stroke="currentColor"
											className="w-6 h-6"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
											/>
										</svg>
									</div>
								</div>
							</div>
							<button className="btn-theme-secondary flex">
								<span>Contact Us Now</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
									/>
								</svg>
							</button>
						</div>
					</div>
					<div className="w-full border-8 rounded-lg overflow-hidden border-troo-secondary">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.001524964486!2d72.7848282757887!3d21.19209838049845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04d198e284e31%3A0x56c3bab7f1aff804!2sTRooTech%20Business%20Solutions%20Pvt.%20Ltd.%20-%20Surat!5e0!3m2!1sen!2sin!4v1702288171816!5m2!1sen!2sin"
							width="100%"
							title="TRooTech"
							height="450"
							style={{ border: 0 }}
							allowfullscreen=""
							loading="lazy"
							referrerpolicy="no-referrer-when-downgrade"
						></iframe>
					</div>
				</div>
			</div>
			<PartnerSection />
		</Layout>
	);
};

export default ContactUs;
