import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import { PRICING_PLANS } from "../constants";
import PricingCard from "../components/PricingCard";
import ReviewSection from "../sections/ReviewSection";
import PartnerSection from "../sections/PartnerSection";

const Pricing = () => {
	return (
		<Layout>
			<SubHeroSection title="Reasonable Pricing Plan" />
			<div className="relative bg-troo-light text-troo-primary w-full flex flex-col items-center">
				<div className="main-container-big w-full py-[100px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-7">
					{PRICING_PLANS.map((data, i) => {
						return <PricingCard data={data} />;
					})}
				</div>
			</div>
			<ReviewSection onlyForm />
			<PartnerSection />
		</Layout>
	);
};

export default Pricing;
