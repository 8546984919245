import React from "react";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import { MdStar } from "react-icons/md";
import { motion } from "framer-motion";

const ReviewCard = ({ data, isDark }) => {
	return (
		<motion.div
		initial={{ y: 20, opacity: 0 }}
			whileInView={{ y: 0, opacity: 1 }}
			transition={{
				type: "spring",
				stiffness: 100,
			}}
			className={`w-full bg-transparent flex flex-col items-start border rounded-xl overflow-hidden ${
				isDark ? "border-troo-tertiary border-opacity-50" : "border-troo-light"
			}`}
		>
			<div className="w-full bg-troo-secondary text-troo-primary flex items-center justify-between gap-5 px-7 py-4 mt-7">
				<div className="flex items-center gap-3">
					<img src={data?.img} alt={data?.title} className="rounded-lg" />
					<div className="flex flex-col items-start gap-1">
						<p className="text-xl font-bold">{data?.title}</p>
						<p className="text-sm">{data?.designation}</p>
					</div>
				</div>
				<div className="text-xl bg-troo-tertiary p-3 rounded-full text-troo-light">
					<BiSolidQuoteAltLeft />
				</div>
			</div>
			<div className="p-7 w-full flex flex-col items-start gap-5">
				<div className="flex items-center p-2 bg-troo-secondary bg-opacity-10 text-troo-secondary rounded-md">
					<MdStar className="text-xl" />
					<MdStar className="text-xl" />
					<MdStar className="text-xl" />
					<MdStar className="text-xl" />
					<MdStar className="text-xl" />
				</div>
				<p>{data?.reviewTxt}</p>
			</div>
		</motion.div>
	);
};

export default ReviewCard;
