import { ROUTES } from "./routes";

export const NAV_ROUTES = [
	{
		path: ROUTES.home,
		name: "Home",
	},
	{
		path: ROUTES.aboutUs,
		name: "About Us",
	},
	{
		name: "Our Services",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.ourServices,
				name: "Services",
			},
			{
				path: ROUTES.serviceDetails,
				name: "Service Details",
			},
		],
	},
	{
		name: "Pages",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.ourTeam,
				name: "Our Team",
			},
			{
				path: ROUTES.teamDetails,
				name: "Team Details",
			},
			{
				path: ROUTES.portfolio,
				name: "Portfolio",
			},
			{
				path: ROUTES.portfolioDetails,
				name: "Portfolio Details",
			},
			{
				path: ROUTES.testimonials,
				name: "Testimonial",
			},
			{
				path: ROUTES.pricingPlans,
				name: "Pricing",
			},
			{
				path: ROUTES.faqs,
				name: "FAQs",
			},
			{
				path: ROUTES.errorPage,
				name: "404",
			},
		],
	},
	{
		name: "Blogs",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.ourBlogs,
				name: "Our Blogs",
			},
			{
				path: ROUTES.blogDetails,
				name: "Blog Details",
			},
		],
	},
	{
		path: ROUTES.contactUs,
		name: "Contact Us",
	},
];
