import React from "react";
import { GoPlus } from "react-icons/go";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const BlogCard = ({ data }) => {
	return (
		<motion.div
			initial={{ y: 20, opacity: 0 }}
			whileInView={{ y: 0, opacity: 1 }}
			transition={{
				type: "spring",
				stiffness: 100,
			}}
			className="w-full p-7 rounded-xl border border-troo-tertiary flex flex-col items-start gap-5"
		>
			<p className="text-2xl font-bold text-troo-tertiary hover:text-troo-secondary transition-all cursor-pointer">
				{data?.title}
			</p>
			<div className="relative w-full rounded-xl overflow-hidden">
				<div className="absolute left-0 top-0 p-4 bg-troo-tertiary bg-opacity-25 w-full h-full hover:bg-opacity-50 flex flex-col items-start transition-all">
					<div className="uppercase text-troo-tertiary bg-troo-secondary py-2 px-5 rounded-lg">
						{data?.publishDate}
					</div>
				</div>
				<img
					src={data?.img}
					alt={data?.title}
					className="w-full object-cover"
				/>
			</div>
			<p>{data?.description}</p>
			<div className="w-full h-[1px] bg-troo-tertiary bg-opacity-20" />
			<div className="w-full flex items-center gap-5 justify-between">
				<Link
					to={ROUTES.blogDetails}
					className="bg-troo-secondary hover:bg-troo-tertiary hover:text-troo-secondary transition-all text-troo-tertiary p-2 rounded-lg"
				>
					<GoPlus className="text-xl" />
				</Link>
				<p>
					Published By <span className="font-bold">{data?.author}</span>
				</p>
			</div>
		</motion.div>
	);
};

export default BlogCard;
