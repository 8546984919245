import React from "react";
import Heading from "../components/Heading";
import { BLOGS } from "../constants";
import BlogCard from "../components/BlogCard";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const BlogSection = ({ isListingPage }) => {
	return (
		<div className="relative bg-troo-primary-light text-troo-primary w-full flex flex-col items-center">
			<div className="main-container-big w-full py-[100px] flex flex-col items-center gap-10">
				{!isListingPage && (
					<Heading
						title="Inside Story Of Our Company"
						headline="Our News and Blogs"
					/>
				)}
				<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
					{BLOGS.map((data, i) => {
						return <BlogCard data={data} key={i} />;
					})}
				</div>
				{!isListingPage && (
					<Link to={ROUTES.ourBlogs}>
						<button className="btn-theme-secondary flex">
							<span>Read All Blogs</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
								/>
							</svg>
						</button>
					</Link>
				)}
			</div>
		</div>
	);
};

export default BlogSection;
