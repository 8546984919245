import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import ReviewSection from "../sections/ReviewSection";
import PartnerSection from "../sections/PartnerSection";
import ServiceImg from "../assets/images/service-details.png";
import ServiceDetailsListItem from "../components/ServiceDetailsListItem";
import ListItemWithThumb from "../components/ListItemWithThumb";
import { FiChevronRight } from "react-icons/fi";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";

const SingleService = () => {
	return (
		<Layout>
			<SubHeroSection title="Lighting Upgrades" />
			<div className="relative bg-troo-light text-troo-tertiary w-full flex flex-col items-center">
				<div className="main-container-big w-full py-[100px] grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 items-start gap-7">
					<div className="lg:col-span-2 xl:col-span-3 flex flex-col items-start gap-4">
						<h3>Service Overview</h3>
						<p className="text-xl font-medium">
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour.
						</p>
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don't look even
							slightly believable. If you are going to use a passage of Lorem
							Ipsum, you need to be sure there isn't anything embarrassing
							hidden in the middle of text. All the Lorem Ipsum generators on
							the Internet tend to repeat predefined chunks as necessary.
						</p>
						<h3>Service Benefits</h3>
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don't look even
							slightly believable. If you are going to use a passage of Lorem
							Ipsum, you need to be sure there isn't anything embarrassing
							hidden in the middle of text.
						</p>
						<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
							<img
								src={ServiceImg}
								className="w-full h-full object-cover"
								alt="service"
							/>
							<div className="flex flex-col items-start gap-6">
								<h4>Benefits</h4>
								<div className="flex flex-col items-start gap-4">
									<ServiceDetailsListItem txt="Full Service Electrical Solutions" />
									<ServiceDetailsListItem txt="Professional Approach To Electrical Issues" />
									<ServiceDetailsListItem txt="Systemized Electrical Maintenance" />
									<ServiceDetailsListItem txt="Safe Installation Of New Electrical Equipment" />
									<ServiceDetailsListItem txt="Professional Customer Service" />
									<ServiceDetailsListItem txt="Value Addition In Standard Services" />
									<ServiceDetailsListItem txt="Gain Better Rates And Costs" />
								</div>
							</div>
						</div>
						<h3>Service Process</h3>
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don't look even
							slightly believable. If you are going to use a passage of Lorem
							Ipsum, you need
						</p>
						<div className="w-full bg-troo-primary-light grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 items-start">
							<div className="w-full flex flex-col items-center justify-center p-5 gap-3 hover:bg-troo-primary transition-all group/sd1 text-center">
								<div className="p-2 rounded-full bg-troo-secondary bg-opacity-20">
									<div className="text-xl font-bold w-14 h-14 rounded-full flex items-center justify-center bg-troo-secondary">
										01
									</div>
								</div>
								<h5 className="group-hover/sd1:text-troo-light transition-all">
									Consult With Us
								</h5>
							</div>
							<div className="w-full flex flex-col items-center justify-center p-5 gap-3 hover:bg-troo-primary transition-all group/sd1 text-center">
								<div className="p-2 rounded-full bg-troo-secondary bg-opacity-20">
									<div className="text-xl font-bold w-14 h-14 rounded-full flex items-center justify-center bg-troo-secondary">
										02
									</div>
								</div>
								<h5 className="group-hover/sd1:text-troo-light transition-all">
									Choose Service
								</h5>
							</div>
							<div className="w-full flex flex-col items-center justify-center p-5 gap-3 hover:bg-troo-primary transition-all group/sd1 text-center">
								<div className="p-2 rounded-full bg-troo-secondary bg-opacity-20">
									<div className="text-xl font-bold w-14 h-14 rounded-full flex items-center justify-center bg-troo-secondary">
										03
									</div>
								</div>
								<h5 className="group-hover/sd1:text-troo-light transition-all">
									We Work on It
								</h5>
							</div>
							<div className="w-full flex flex-col items-center justify-center p-5 gap-3 hover:bg-troo-primary transition-all group/sd1 text-center">
								<div className="p-2 rounded-full bg-troo-secondary bg-opacity-20">
									<div className="text-xl font-bold w-14 h-14 rounded-full flex items-center justify-center bg-troo-secondary">
										04
									</div>
								</div>
								<h5 className="group-hover/sd1:text-troo-light transition-all">
									Get result
								</h5>
							</div>
						</div>
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don't look even
							slightly believable. If you are going.
						</p>
						<h3>Why Choose Oue Services</h3>
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised.
						</p>
						<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
							<ListItemWithThumb title="Safety Gaurantee" />
							<ListItemWithThumb title="Best Quality Service" />
							<ListItemWithThumb title="Professional Electrician" />
							<ListItemWithThumb title="Reasonable Price" />
						</div>
					</div>
					<div className="w-full flex flex-col items-start gap-6">
						<div className="p-6 bg-troo-primary-light w-full flex flex-col items-start gap-4">
							<h4>Other Services</h4>
							<div className="w-[20%] h-[2px] bg-troo-secondary" />
							<div className="flex w-full flex-col items-start">
								<div className="py-4 border-b border-troo-tertiary border-opacity-20 w-full flex items-center justify-between">
									<p>Electrical Testing</p>
									<div>
										<FiChevronRight />
									</div>
								</div>
								<div className="py-4 border-b border-troo-tertiary border-opacity-20 w-full flex items-center justify-between">
									<p>Electric Installation</p>
									<div>
										<FiChevronRight />
									</div>
								</div>
								<div className="py-4 border-b border-troo-tertiary border-opacity-20 w-full flex items-center justify-between">
									<p>Cable Jointing Services</p>
									<div>
										<FiChevronRight />
									</div>
								</div>
								<div className="py-4 border-b border-troo-tertiary border-opacity-20 w-full flex items-center justify-between">
									<p>High Voltage Maintenance</p>
									<div>
										<FiChevronRight />
									</div>
								</div>
								<div className="py-4 border-b border-troo-tertiary border-opacity-20 w-full flex items-center justify-between">
									<p>Substation Earthing</p>
									<div>
										<FiChevronRight />
									</div>
								</div>
								<div className="py-4 border-b border-troo-tertiary border-opacity-20 w-full flex items-center justify-between">
									<p>Repairing Irrigation Pumps</p>
									<div>
										<FiChevronRight />
									</div>
								</div>
								<div className="py-4 border-b border-troo-tertiary border-opacity-20 w-full flex items-center justify-between">
									<p>Emergency Call Outs</p>
									<div>
										<FiChevronRight />
									</div>
								</div>
							</div>
						</div>
						<div className="w-full bg-troo-secondary flex flex-col items-center gap-5 text-troo-tertiary rounded-xl p-6">
							<p className="text-center text-2xl font-bold">
								If You Need Any Help Contact With Us
							</p>
							<div className="w-full p-4 rounded-lg bg-troo-tertiary text-troo-light flex flex-col items-center">
								<HiOutlineChatBubbleLeftRight className="text-5xl" />
								<p className="font-bold mt-4">+44 123 456 7890</p>
								<p className="font-bold">info@trootheme.com</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ReviewSection onlyForm />
			<PartnerSection />
		</Layout>
	);
};

export default SingleService;
