import React from "react";
import ErrorImg from "../assets/images/error-vector.svg";
const ErrorPage = () => {
	return (
		<div className="w-full min-h-screen px-10 py-20 flex flex-col items-center justify-center gap-5 lg:gap-10">
			<img src={ErrorImg} alt="404" className="w-full md:w-[40%]" />
			<h2>Page Not Found</h2>
			<p className="text-troo-tertiary text-center text-sm md:text-base lg:text-2xl">
				We Are Sorry, The Page You Requested Can Not Be Found.
			</p>
			<button className="btn-theme-secondary flex">
				<span>Go to Homepage</span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth={1.5}
					stroke="currentColor"
					className="w-6 h-6"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
					/>
				</svg>
			</button>
		</div>
	);
};

export default ErrorPage;
