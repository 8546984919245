import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import { PROJECTS } from "../constants";
import ProjectCard from "../components/ProjectCard";

const Projects = () => {
	return (
		<Layout>
			<SubHeroSection title="Recently We Done Some Project" />
			<div className="relative bg-troo-primary-light text-troo-primary w-full flex flex-col items-center">
				<div className="main-container-big w-full py-[100px] grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-center gap-5">
					{PROJECTS.map((data, i) => {
						return <ProjectCard key={i} data={data} />;
					})}
				</div>
			</div>
		</Layout>
	);
};

export default Projects;
