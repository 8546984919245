import React from "react";
import { HiOutlineThumbUp } from "react-icons/hi";

const ListItemWithThumb = ({ title }) => {
	return (
		<div className="flex items-start gap-4">
			<div className="p-2 bg-troo-secondary bg-opacity-20 rounded-lg">
				<div className="bg-troo-secondary text-troo-light p-2 rounded-lg ">
					<HiOutlineThumbUp className="text-xl" />
				</div>
			</div>
			<div className="flex flex-col items-start gap-2 mt-2 text-troo-tertiary">
				<p className="text-2xl font-bold">{title}</p>
				<p>
					There are many variations of passages they of Lorem Ipsum available,
					but the majority have suffered alteration.
				</p>
			</div>
		</div>
	);
};

export default ListItemWithThumb;
