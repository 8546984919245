import React from "react";
import Flash from "../assets/images/service-card-flash.svg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";
const ServiceCard = ({ data }) => {
	return (
		<motion.div
			initial={{ y: 30, opacity: 0 }}
			whileInView={{ y: 1, opacity: 1 }}
			transition={{
				type: "spring",
				stiffness: 100,
			}}
			className="w-full bg-troo-light p-8 flex flex-col items-start gap-4 md:gap-8 lg:gap-10 xl:gap-12 text-troo-tertiary border border-troo-tertiary hover:border-troo-secondary rounded-lg transition-all cursor-pointer hover:shadow-2xl relative group/serviceCard"
		>
			<img
				src={Flash}
				alt="flash"
				className="absolute right-10 top-24 group-hover/serviceCard:opacity-100 opacity-0 transition-all"
			/>
			<p className="text-xl md:text-2xl font-bold">{data?.title}</p>
			<img src={data?.icon} alt={data?.title} className="md:w-auto w-16" />
			<p className="text-sm md:text-base">{data?.description}</p>
			<Link
				to={ROUTES.serviceDetails}
				className="text-2xl p-3 bg-troo-secondary text-troo-primary aspect-square w-10 h-10 flex items-center justify-center rounded-md"
			>
				+
			</Link>
		</motion.div>
	);
};

export default ServiceCard;
