import React from "react";
import logo from "../../shared/assets/images/Logo.svg";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoChatbubblesOutline } from "react-icons/io5";
import { NAV_ROUTES } from "../constants/navRoutes";
import PrimarySidebar from "./PrimarySidebar";
import { motion } from "framer-motion";

const Header = () => {
	const location = useLocation();
	return (
		<div className="relative bg-troo-primary text-troo-primary w-full flex flex-col items-center">
			<div className="main-container bg-troo-primary w-full flex flex-col items-center">
				<div className="w-full flex h-full gap-4 lg:gap-8 items-center justify-between pt-4 pb-4 lg:pt-6 lg:pb-12">
					<Link to="/">
						<img
							src={logo}
							alt="brand logo"
							className="py-4 w-64 xl:w-[300px]"
						/>
					</Link>
					<div className="hidden lg:flex items-center gap-8">
						<div className="flex items-center gap-3">
							<div className="flex item-center gap-3 text-troo-light">
								<div className="text-3xl p-3 aspect-square rounded-md bg-troo-secondary flex items-center justify-center">
									<IoChatbubblesOutline />
								</div>
								<div className="text-sm xl:text-base flex flex-col items-start">
									<h6>+44 123 456 7890</h6>
									<h6>contact@troocarrent.com</h6>
								</div>
							</div>
							<div className="flex item-center gap-3 text-troo-light">
								<div className="text-3xl p-3 aspect-square rounded-md bg-troo-secondary flex items-center justify-center">
									<HiOutlineLocationMarker />
								</div>
								<div className="flex flex-col items-start">
									<h6>
										4516 School Street
										<br /> Danbury, CT, Canada
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div className="text-troo-secondary bg-transparent border border-troo-secondary p-2 rounded-full cursor-pointer lg:hidden">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-8 h-8"
							data-hs-overlay="#hs-overlay-example"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
							/>
						</svg>
					</div>
				</div>
			</div>
			<div className="absolute -bottom-[30%] main-container w-full bg-transparent hidden lg:flex flex-col items-center -mt-10 z-[999999]">
				<div className="bg-troo-light px-12 py-3 rounded-full shadow-lg w-full flex items-center gap-8 justify-between">
					<div className="hidden lg:flex items-center gap-6 lg:gap-8 xl:gap-10 text-sm lg:text-lg">
						{NAV_ROUTES.map((route, i) => {
							if (route.isMultiple) {
								return (
									<div class="hs-dropdown relative inline-flex">
										<div className="flex items-center gap-3 hs-dropdown-toggle">
											<p className="text-sm py-2 lg:text-base cursor-pointer font-medium">
												{route.name} +
											</p>
										</div>
										<div
											class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] hs-dropdown-open:opacity-100 opacity-0 w-72 hidden mt-2 min-w-[15rem] shadow-md rounded-lg z-[999999]"
											aria-labelledby="hs-dropdown-default"
										>
											<motion.div
												initial={{ y: 20, opacity: 0 }}
												whileInView={{ y: 1, opacity: 1 }}
												transition={{ type: "spring", stiffness: 100 }}
												className="flex flex-col items-start gap-5 w-full bg-troo-secondary text-troo-primary py-4 px-6 rounded-lg"
											>
												{route.subPages?.map((nav, i) => {
													return <Link to={nav.path}>{nav.name}</Link>;
												})}
											</motion.div>
										</div>
									</div>
								);
							} else {
								return (
									<Link
										className={`text-sm lg:text-base cursor-pointer py-2 border-b border-transparent hover:text-troo-primary hover:border-troo-primary transition-all duration-150 ease-linear font-medium ${
											location.pathname === route.path && "activeNav"
										}`}
										to={route.path}
									>
										{route.name}
									</Link>
								);
							}
						})}
					</div>
					<div className="flex items-center gap-10">
						<button className="btn-theme-secondary flex">
							<span>Request Services</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
								/>
							</svg>
						</button>
						<div className="hidden 2xl:flex items-center gap-3">
							<p>Social</p>
							<div className="text-xl p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-tertiary bg-opacity-20 text-troo-tertiary hover:bg-troo-secondary transition-all">
								<FaFacebookF />
							</div>
							<div className="text-xl p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-tertiary bg-opacity-20 text-troo-tertiary hover:bg-troo-secondary transition-all">
								<FaXTwitter />
							</div>
							<div className="text-xl p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-tertiary bg-opacity-20 text-troo-tertiary hover:bg-troo-secondary transition-all">
								<FaLinkedinIn />
							</div>
							<div className="text-xl p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-tertiary bg-opacity-20 text-troo-tertiary hover:bg-troo-secondary transition-all">
								<FaInstagram />
							</div>
						</div>
					</div>
				</div>
			</div>
			<PrimarySidebar />
		</div>
	);
};

export default Header;
