import React from "react";
import { motion } from "framer-motion";

const StatCard = ({ statNo, txt }) => {
	return (
		<motion.div
			initial={{ y: 20, opacity: 0 }}
			whileInView={{ y: 0, opacity: 1 }}
			transition={{
				type: "spring",
				stiffness: 100,
			}}
			className="w-full flex flex-col items-center text-center gap-10 z-10"
		>
			<div className="text-2xl md:text-3xl lg:text-4xl font-bold w-[100px] h-[100px] lg:w-[120px] lg:h-[120px] aspect-square text-troo-tertiary bg-troo-secondary rounded-full flex items-center justify-center relative">
				<div className="lg:w-[152px] lg:h-[77px] w-[132px] h-[72px] -bottom-4 absolute bg-white lg:rounded-bl-[100px] -z-10 lg:rounded-br-[100px] rounded-bl-[172px] rounded-br-[172px]" />
				{statNo}
			</div>
			<p className="text-2xl font-bold text-troo-light">{txt}</p>
		</motion.div>
	);
};

export default StatCard;
