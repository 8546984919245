import React from "react";
import Electrecian from "../assets/images/about-electrician.png";
import Heading from "../components/Heading";
import { HiOutlineThumbUp } from "react-icons/hi";
import { LuPhoneCall } from "react-icons/lu";
import { motion } from "framer-motion";
const AboutSection = () => {
	return (
		<div className="relative bg-troo-primary-light text-troo-primary w-full flex flex-col items-center">
			<div className="main-container-big w-full py-[100px] grid grid-cols-1 lg:grid-cols-2 items-center gap-10">
				<div className="w-full relative">
					<motion.img
						initial={{ y: 10, x: 10, scale: 0.9, opacity: 0 }}
						whileInView={{ y: 0, x: 0, scale: 1, opacity: 1 }}
						transition={{
							type: "spring",
							stiffness: 100,
						}}
						src={Electrecian}
						alt="Electrecian"
						className="w-full pr-5 pb-5"
					/>
					<div className="absolute w-[60%] h-[70%] right-0 bottom-0 border-r border-b border-troo-secondary bg-transparent" />
				</div>
				<div className="flex flex-col items-start gap-7">
					<Heading
						isStart
						headline="What We Do Best"
						title={
							<>
								Bringing Light to Your Home,
								<br /> One Wire At a Time
							</>
						}
					/>
					<p>
						There are many variations of passages they of Lorem Ipsum available,
						but the majority have suffered alteration also we in some form, by
						injected humour, or randomised words which don't look even slightly
						believable. If you are going to use a passage of Lorem Ipsum
					</p>
					<div className="flex items-start gap-4">
						<div className="p-2 bg-troo-secondary bg-opacity-20 rounded-lg">
							<div className="bg-troo-secondary text-troo-light p-2 rounded-lg">
								<HiOutlineThumbUp />
							</div>
						</div>
						<div className="flex flex-col items-start gap-3 text-troo-tertiary">
							<p className="text-2xl font-bold">Safety Gaurantee</p>
							<p>
								There are many variations of passages they of Lorem Ipsum
								available, but the majority have suffered alteration.
							</p>
						</div>
					</div>
					<div className="flex items-start gap-4">
						<div className="p-2 bg-troo-secondary bg-opacity-20 rounded-lg">
							<div className="bg-troo-secondary text-troo-light p-2 rounded-lg">
								<HiOutlineThumbUp />
							</div>
						</div>
						<div className="flex flex-col items-start gap-3 text-troo-tertiary">
							<p className="text-2xl font-bold">Professional Electrician</p>
							<p>
								There are many variations of passages they of Lorem Ipsum
								available, but the majority have suffered alteration.
							</p>
						</div>
					</div>
					<div className="flex flex-wrap items-center gap-7">
						<button className="btn-theme-secondary flex">
							<span>Discover More</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
								/>
							</svg>
						</button>
						<div className="flex items-center gap-4">
							<div className="p-1.5 rounded-full bg-troo-secondary bg-opacity-20">
								<div className="text-xl rounded-full p-4 bg-troo-secondary text-troo-tertiary">
									<LuPhoneCall />
								</div>
							</div>
							<div className="text-troo-tertiary">
								<p className="text-xl font-bold">+820 123 456 7890</p>
								<p>Call Us today!</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutSection;
