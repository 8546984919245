import React from "react";
import StatCard from "../components/StatCard";
import Heading from "../components/Heading";
import { PROJECTS } from "../constants";
import ProjectCard from "../components/ProjectCard";

const StatisticsWorkSection = () => {
	return (
		<div className="w-full flex flex-col items-center bg-troo-primary-light">
			<div className="w-full bg-troo-primary bg-stat-vector bg-no-repeat bg-cover bg-center text-troo-light">
				<div className="main-container-big w-full pt-[100px] pb-[140px] flex flex-col items-center gap-7">
					<h2 className="font-outline-2 font-outline-2 text-transparent text-6xl">
						OUR STATISTICS
					</h2>
					<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
						<StatCard statNo="20k" txt="Success Project" />
						<StatCard statNo="200+" txt="Awesome Team" />
						<StatCard statNo="14k" txt="Happy Clients" />
						<StatCard statNo="30+" txt="Year Of Experience" />
					</div>
				</div>
			</div>
			<div className="main-container-big w-full py-[100px] flex flex-col items-center text-troo-primary bg-transparent -mt-20 gap-7">
				<div className="w-full -mt-20 flex flex-col items-center bg-troo-primary-light p-12">
					<Heading
						headline="Our Recent Works"
						title={
							<>
								Have a Look!
								<br /> We Done Some Project Recently
							</>
						}
					/>
					<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-16">
						{PROJECTS.map((data, i) => {
							return (
								<div
									className={i === 0 || i === 5 ? "md:col-span-2" : "w-full"}
								>
									<ProjectCard key={i} data={data} />
								</div>
							);
						})}
					</div>
					<button className="btn-theme-secondary flex mt-16">
						<span>View All Projects</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-6 h-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};

export default StatisticsWorkSection;
