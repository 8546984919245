import React from "react";
import Layout from "../components/Layout";
import HeroSection from "../sections/HeroSection";
import ServiceSection from "../sections/ServiceSection";
import AboutSection from "../sections/AboutSection";
import StatisticsWorkSection from "../sections/StatisticsWorkSection";
import TeamSection from "../sections/TeamSection";
import WhyUsSection from "../sections/WhyUsSection";
import ReviewSection from "../sections/ReviewSection";
import BlogSection from "../sections/BlogSection";
import PartnerSection from "../sections/PartnerSection";

const Home = () => {
	return (
		<Layout>
			<HeroSection />
			<ServiceSection />
			<AboutSection />
			<StatisticsWorkSection />
			<TeamSection />
			<WhyUsSection />
			<ReviewSection />
			<BlogSection />
			<PartnerSection />
		</Layout>
	);
};

export default Home;
