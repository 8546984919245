import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import PartnerSection from "../sections/PartnerSection";
import ReviewSection from "../sections/ReviewSection";
import ProjectDetails from "../assets/images/project-details.png";
import ProjectDetails1 from "../assets/images/project-details-1.png";
import ProjectDetails2 from "../assets/images/project-details-2.png";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const SingleProject = () => {
	return (
		<Layout>
			<SubHeroSection title="Single Project" />
			<div className="relative bg-troo-light text-troo-tertiary w-full flex flex-col items-center">
				<div className="main-container-big w-full py-[100px] flex flex-col items-start gap-6">
					<h3>Fixing the wires on a ceiling light fixure.</h3>
					<h4>Project Overview</h4>
					<p className="md:text-base text-sm">
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable. If you are going to use a passage of Lorem Ipsum, you
						need to be sure there isn't anything embarrassing hidden in the
						middle of text. All the Lorem Ipsum generators on the Internet tend
						to repeat predefined chunks as necessary.
					</p>
					<div className="w-full flex flex-col items-center">
						<img
							src={ProjectDetails}
							alt="ProjectDetails"
							className="w-full object-cover lg:aspect-auto aspect-video rounded-xl"
						/>
						<div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-x-4 shadow-xl gap-y-4 md:gap-y-8 w-[90%] bg-troo-primary text-troo-light self-center p-6 items-center rounded-xl -mt-10">
							<div className="flex flex-col items-center text-center gap-1 lg:gap-2">
								<p className="text-sm sm:text-base md:text-lg lg:text-xl font-bold">
									Client Name
								</p>
								<p className="text-xs sm:text-sm lg:text-base">Client Name</p>
							</div>
							<div className="flex flex-col items-center text-center gap-1 lg:gap-2">
								<p className="text-sm sm:text-base md:text-lg lg:text-xl font-bold">
									Category
								</p>
								<p className="text-xs sm:text-sm lg:text-base">Light Fitting</p>
							</div>
							<div className="flex flex-col items-center text-center gap-1 lg:gap-2">
								<p className="text-sm sm:text-base md:text-lg lg:text-xl font-bold">
									Start Date
								</p>
								<p className="text-xs sm:text-sm lg:text-base">25/07/2023</p>
							</div>
							<div className="flex flex-col items-center text-center gap-1 lg:gap-2">
								<p className="text-sm sm:text-base md:text-lg lg:text-xl font-bold">
									End Date
								</p>
								<p className="text-xs sm:text-sm lg:text-base">25/07/2023</p>
							</div>
							<div className="flex flex-col items-center text-center gap-1 lg:gap-2">
								<p className="text-sm sm:text-base md:text-lg lg:text-xl font-bold">
									Share
								</p>
								<div className="flex items-center gap-1">
									<div className="text-xs sm:text-sm lg:text-base p-1.5 sm:p-2 lg:p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-light bg-opacity-10 text-troo-light hover:bg-troo-secondary transition-all">
										<FaFacebookF />
									</div>
									<div className="text-xs sm:text-sm lg:text-base p-1.5 sm:p-2 lg:p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-light bg-opacity-10 text-troo-light hover:bg-troo-secondary transition-all">
										<FaXTwitter />
									</div>
									<div className="text-xs sm:text-sm lg:text-base p-1.5 sm:p-2 lg:p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-light bg-opacity-10 text-troo-light hover:bg-troo-secondary transition-all">
										<FaLinkedinIn />
									</div>
									<div className="text-xs sm:text-sm lg:text-base p-1.5 sm:p-2 lg:p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-light bg-opacity-10 text-troo-light hover:bg-troo-secondary transition-all">
										<FaInstagram />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-full flex flex-col items-start gap-5">
						<div className="w-full flex lg:flex-row flex-col gap-3 items-start justify-between">
							<h2 className="lg:w-[20%]">Challenges</h2>
							<div className="lg:w-[75%] flex flex-col items-start gap-2 ">
								<p className="md:text-base text-sm">
									There are many variations of passages of Lorem Ipsum
									available, but the majority have suffered alteration in some
									form, by injected humour, or randomised words which don't look
									even slightly believable. If you are going to use a passage of
									Lorem Ipsum, you need to be sure there isn't anything
									embarrassing hidden in the middle of text. All the Lorem Ipsum
									generators on the Internet tend to repeat predefined chunks as
									necessary, making this the first true generator on the
									Internet. It uses a dictionary of over 200 Latin words,
									combined with a handful of model sentence structures, to
									generate Lorem Ipsum which looks reasonable. The generated
									Lorem Ipsum is therefore always free from repetition, injected
									humour, or non-characteristic words etc.
								</p>
								<p className="md:text-base text-sm">
									There are many variations of passages of Lorem Ipsum
									available, but the majority have suffered alteration in some
									form, by injected humour, or randomised words which don't look
									even slightly believable. If you are going to use a passage of
									Lorem Ipsum, you need to be sure there isn't anything
									embarrassing hidden in the middle of text.
								</p>
							</div>
						</div>
						<div className="w-full flex lg:flex-row flex-col gap-3 items-start justify-between">
							<h2 className="lg:w-[20%]">Solutions</h2>
							<div className="lg:w-[75%] flex flex-col items-start gap-2 ">
								<p className="md:text-base text-sm">
									There are many variations of passages of Lorem Ipsum
									available, but the majority have suffered alteration in some
									form, by injected humour, or randomised words which don't look
									even slightly believable. If you are going to use a passage of
									Lorem Ipsum, you need to be sure there isn't anything
									embarrassing hidden in the middle of text. All the Lorem Ipsum
									generators on the Internet tend to repeat predefined chunks as
									necessary
								</p>
								<p className="md:text-base text-sm">
									There are many variations of passages of Lorem Ipsum
									available, but the majority have suffered alteration in some
									form, by injected humour, or randomised words which don't look
									even slightly believable. If you are going to use a passage of
									Lorem Ipsum, you need to be sure there isn't anything
									embarrassing hidden in the middle of text. All the Lorem Ipsum
									generators on the Internet tend to repeat predefined chunks as
									necessary, making this the first true.
								</p>
							</div>
						</div>
						<div className="w-full flex lg:flex-row flex-col  gap-3items-start justify-between">
							<h2 className="lg:w-[20%]">Results</h2>
							<div className="lg:w-[75%] flex flex-col items-start gap-2 ">
								<p className="md:text-base text-sm">
									There are many variations of passages of Lorem Ipsum
									available, but the majority have suffered alteration in some
									form, by injected humour, or randomised words which don't look
									even slightly believable.
								</p>
								<p className="md:text-base text-sm">
									There are many variations of passages of Lorem Ipsum
									available, but the majority have suffered alteration in some
									form, by injected humour, or randomised words which don't look
									even slightly believable. If you are going to use a passage of
									Lorem Ipsum, you need to be sure there isn't anything
									embarrassing hidden in the middle of text. All the Lorem Ipsum
									generators on the Internet tend to repeat predefined chunks as
									necessary, making this the first true generator on the
									Internet. It uses a dictionary of over 200 Latin words,
									combined.
								</p>
							</div>
						</div>
					</div>
					<div className="w-full grid grid-cols-2 gap-5">
						<img
							src={ProjectDetails1}
							alt="ProjectDetails1"
							className="w-full h-full object-cover rounded-lg"
						/>
						<img
							src={ProjectDetails2}
							alt="ProjectDetails2"
							className="w-full h-full object-cover rounded-lg"
						/>
					</div>
				</div>
			</div>
			<ReviewSection onlyForm />
			<PartnerSection />
		</Layout>
	);
};

export default SingleProject;
