import React from "react";
import Heading from "../components/Heading";
import ListItemWithThumb from "../components/ListItemWithThumb";
import WhyUsImg from "../assets/images/why-us.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";
const WhyUsSection = () => {
	return (
		<div className="relative bg-whyus-back bg-center bg-no-repeat text-troo-primary w-full flex flex-col items-center">
			<div className="main-container-big w-full py-[100px] grid grid-cols-1 lg:grid-cols-2 flex-col items-center gap-10">
				<div className="w-fill flex flex-col items-start gap-5">
					<Heading
						isStart
						headline="Why Choose Us"
						title={
							<>
								We Are Expert in All Electric <br />
								Solutions
							</>
						}
					/>
					<p>
						There are many variations of passages they of Lorem Ipsum available,
						but the majority have suffered alteration also we in some form.
					</p>
					<ListItemWithThumb title="Safety Gaurantee" />
					<ListItemWithThumb title="Professional Electrician" />
					<ListItemWithThumb title="Best Quality Service" />
					<ListItemWithThumb title="Reasonable Price" />
					<Link to={ROUTES.contactUs}>
						<button className="btn-theme-secondary flex">
							<span>Contact With Us</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
								/>
							</svg>
						</button>
					</Link>
				</div>
				<div className="w-full h-full overflow-hidden border-8 border-troo-light">
					<img
						src={WhyUsImg}
						alt="WhyUsImg"
						className="w-full h-full object-cover"
					/>
				</div>
			</div>
		</div>
	);
};

export default WhyUsSection;
