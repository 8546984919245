import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "./shared/constants/routes";
import Home from "./shared/pages/Home";
import AboutUs from "./shared/pages/AboutUs";
import Services from "./shared/pages/Services";
import Projects from "./shared/pages/Projects";
import Teams from "./shared/pages/Teams";
import Testimonial from "./shared/pages/Testimonial";
import FAQ from "./shared/pages/FAQ";
import Blogs from "./shared/pages/Blogs";
import ErrorPage from "./shared/pages/ErrorPage";
import ContactUs from "./shared/pages/ContactUs";
import Pricing from "./shared/pages/Pricing";
import SingleService from "./shared/pages/SingleService";
import SingleProject from "./shared/pages/SingleProject";
import SingleTeam from "./shared/pages/SingleTeam";
import SingleBlog from "./shared/pages/SingleBlog";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={ROUTES.home} element={<Home />} />
				<Route path={ROUTES.aboutUs} element={<AboutUs />} />
				<Route path={ROUTES.ourServices} element={<Services />} />
				<Route path={ROUTES.portfolio} element={<Projects />} />
				<Route path={ROUTES.ourTeam} element={<Teams />} />
				<Route path={ROUTES.testimonials} element={<Testimonial />} />
				<Route path={ROUTES.faqs} element={<FAQ />} />
				<Route path={ROUTES.ourBlogs} element={<Blogs />} />
				<Route path={ROUTES.errorPage} element={<ErrorPage />} />
				<Route path={ROUTES.contactUs} element={<ContactUs />} />
				<Route path={ROUTES.pricingPlans} element={<Pricing />} />
				<Route path={ROUTES.serviceDetails} element={<SingleService />} />
				<Route path={ROUTES.portfolioDetails} element={<SingleProject />} />
				<Route path={ROUTES.teamDetails} element={<SingleTeam />} />
				<Route path={ROUTES.blogDetails} element={<SingleBlog />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
