import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import ReviewSection from "../sections/ReviewSection";
import PartnerSection from "../sections/PartnerSection";
import { REVIEWS } from "../constants";
import ReviewCard from "../components/ReviewCard";

const Testimonial = () => {
	return (
		<Layout>
			<SubHeroSection title="Our Clients Feedback" />
			<div className="relative bg-troo-light text-troo-primary w-full flex flex-col items-center">
				<div className="main-container-big w-full py-[100px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
					{REVIEWS.map((data, i) => {
						return <ReviewCard key={i} data={data} isDark />;
					})}
				</div>
			</div>
			<ReviewSection onlyForm />
			<PartnerSection />
		</Layout>
	);
};

export default Testimonial;
