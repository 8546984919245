import React from "react";
import Logo1 from "../assets/images/logo1.png";
import Logo2 from "../assets/images/logo2.png";
import Logo3 from "../assets/images/logo3.png";
import Logo4 from "../assets/images/logo4.png";
import Logo5 from "../assets/images/logo5.png";

const PartnerSection = () => {
	return (
		<div className="relative bg-troo-light text-troo-primary w-full flex flex-col items-center">
			<div className="main-container-big w-full py-[100px] flex lg:flex-row flex-col items-center gap-10">
				<p className="text-2xl lg:w-[30%] font-bold">
					We Are Very Fortunate to Work With These Amazing Partners
				</p>
				<div className="lg:w-[70%] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">
					<img src={Logo1} alt="sponser" className="w-full" />
					<img src={Logo2} alt="sponser" className="w-full" />
					<img src={Logo3} alt="sponser" className="w-full" />
					<img src={Logo4} alt="sponser" className="w-full" />
					<img src={Logo5} alt="sponser" className="w-full" />
					<img src={Logo1} alt="sponser" className="w-full" />
					<img src={Logo2} alt="sponser" className="w-full" />
					<img src={Logo3} alt="sponser" className="w-full" />
					<img src={Logo4} alt="sponser" className="w-full" />
					<img src={Logo5} alt="sponser" className="w-full" />
				</div>
			</div>
		</div>
	);
};

export default PartnerSection;
