import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import PartnerSection from "../sections/PartnerSection";
import BlogSection from "../sections/BlogSection";

const Blogs = () => {
	return (
		<Layout>
			<SubHeroSection title="Electrical News & Blogs" />
			<BlogSection isListingPage />
			<PartnerSection />
		</Layout>
	);
};

export default Blogs;
