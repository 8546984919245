import React from "react";
import { IoFlashSharp } from "react-icons/io5";
const Heading = ({ headline, isStart, title }) => {
	return (
		<div
			className={`flex flex-col gap-5 ${
				isStart ? "items-start text-left" : "items-center text-center"
			}`}
		>
			<div className="flex items-center gap-1">
				<div className="text-2xl text-troo-secondary">
					<IoFlashSharp />
				</div>
				<p className="text-sm sm:text-base md:text-lg lg:text-xl">{headline}</p>
			</div>
			<p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold">
				{title}
			</p>
		</div>
	);
};

export default Heading;
