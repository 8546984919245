import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { motion } from "framer-motion";

const SubHeroSection = ({ title }) => {
	return (
		<div className="py-[100px] w-full bg-troo-primary bg-sub-hero bg-center bg-contain bg-no-repeat">
			<div className="main-container-big flex flex-col h-full items-center">
				<div className="flex w-full items-center flex-wrap justify-center lg:justify-between text-troo-light gap-6">
					<motion.h2
						initial={{ y: -10, scale: 0.9, opacity: 0 }}
						whileInView={{ y: 1, scale: 1, opacity: 1 }}
							transition={{
							type: "spring",
							stiffness: 100,
						}}
						className="font-bold"
					>
						{title}
					</motion.h2>
					<Breadcrumbs />
				</div>
			</div>
		</div>
	);
};

export default SubHeroSection;
