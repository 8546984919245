import React from "react";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { motion } from "framer-motion";

const TeamCard = ({ data }) => {
	return (
		<motion.div
			initial={{ y: 20, opacity: 0 }}
			whileInView={{ y: 0, opacity: 1 }}
			transition={{
				type: "spring",
				stiffness: 100,
			}}
			className="w-full border border-troo-primary text-troo-primary rounded-lg flex items-start"
		>
			<div className="w-full flex flex-col items-start justify-between h-full">
				<div className="p-7 flex flex-col items-start">
					<p className="text-2xl font-bold">{data?.titie}</p>
					<p>{data?.designation}</p>
				</div>
				<img
					src={data?.img}
					alt={data?.title}
					className="rounded-lg w-full object-cover"
				/>
			</div>
			<div className="p-8 flex flex-col items-center gap-3">
				<div className="rounded-lg bg-troo-tertiary bg-opacity-10 p-3">
					<FaFacebookF />
				</div>
				<div className="rounded-lg bg-troo-tertiary bg-opacity-10 p-3">
					<FaXTwitter />
				</div>
				<div className="rounded-lg bg-troo-tertiary bg-opacity-10 p-3">
					<FaInstagram />
				</div>
				<div className="rounded-lg bg-troo-tertiary bg-opacity-10 p-3">
					<FaLinkedinIn />
				</div>
				<div className="w-[1px] bg-troo-tertiary bg-opacity-50 h-[120px] mt-5"></div>
			</div>
		</motion.div>
	);
};

export default TeamCard;
