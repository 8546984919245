import React from "react";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { HiOutlineEnvelopeOpen } from "react-icons/hi2";
import logo from "../../shared/assets/images/Logo.svg";
import FooterTopVector from "../../shared/assets/images/footer-top-vector.svg";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<div className="bg-troo-primary bg-footer-vector bg-cover bg-no-repeat text-troo-light bg-center w-full">
			<div className="w-fullflex flex-col">
				<div className="main-container relative pt-[80px] pb-[50px] w-full grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 xl:gap-16">
					<img
						src={FooterTopVector}
						className="w-full absolute -top-4 left-0"
						alt="top vector"
					/>
					<div className="flex flex-col items-start gap-7">
						<div className="flex flex-col items-start gap-2">
							<p className="text-2xl font-bold">Information</p>
							<div className="w-[30px] h-0.5 bg-troo-secondary" />
						</div>
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have Lorem Ipsum available suffered alteration in
							some.
						</p>
						<div className="hidden 2xl:flex items-center gap-3">
							<p>Social</p>
							<div className="text-base p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-light bg-opacity-20 text-troo-light hover:text-troo-primary hover:bg-troo-secondary transition-all">
								<FaFacebookF />
							</div>
							<div className="text-base p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-light bg-opacity-20 text-troo-light hover:text-troo-primary hover:bg-troo-secondary transition-all">
								<FaXTwitter />
							</div>
							<div className="text-base p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-light bg-opacity-20 text-troo-light hover:text-troo-primary hover:bg-troo-secondary transition-all">
								<FaLinkedinIn />
							</div>
							<div className="text-base p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-light bg-opacity-20 text-troo-light hover:text-troo-primary hover:bg-troo-secondary transition-all">
								<FaInstagram />
							</div>
						</div>
					</div>
					<div className="flex flex-col items-start gap-7">
						<div className="flex flex-col items-start gap-2">
							<p className="text-2xl font-bold">Contact Info</p>
							<div className="w-[30px] h-0.5 bg-troo-secondary" />
						</div>
						<p className="text-xl font-bold">
							4516 School Street
							<br /> Danbury, CT, Canada
						</p>
						<p className="text-xl font-bold">+44 123 456 7890</p>
						<p className="text-xl font-bold underline text-troo-secondary">
							contact@troothemes.com
						</p>
					</div>
					<div className="flex flex-col items-start gap-7">
						<div className="flex flex-col items-start gap-2">
							<p className="text-2xl font-bold">Our Services</p>
							<div className="w-[30px] h-0.5 bg-troo-secondary" />
						</div>
						<div className="flex flex-col items-start gap-3.5">
							<p>Quality Testing</p>
							<p>Repairs & fixes</p>
							<p>Repairing & maintenance</p>
							<p>Electrical instruments</p>
							<p>Electrical upgrades</p>
						</div>
					</div>
					<div className="w-full lg:col-span-3 xl:col-span-1 bg-troo-light bg-opacity-5 p-7 flex flex-col items-start gap-4">
						<p className="text-2xl font-bold">Our Newslatter</p>
						<div className="w-full border-b border-troo-light pb-1 flex items-center gap-3 justify-between">
							<input
								type="text"
								placeholder="Email"
								className="bg-transparent outline-none w-full border-none px-0 placeholder-gray-300"
							/>
							<div className="text-2xl">
								<HiOutlineEnvelopeOpen />
							</div>
						</div>
						<div className="flex items-center gap-3">
							<input type="checkbox" />
							<p className="text-sm">Accept Terms & Conditions.</p>
						</div>
						<button className="btn-theme-secondary hover:border-troo-light hover:text-troo-light flex">
							<span>Subscribe Now</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
								/>
							</svg>
						</button>
					</div>
				</div>
				<div className="main-container flex flex-wrap lg:flex-row flex-col bg-troo-primary bg-opacity-10 gap-8 items-center justify-between w-full py-6 border-y border-troo-light border-opacity-20">
					<Link to="/">
						<img
							src={logo}
							alt="brand logo"
							className="py-4 w-64 xl:w-[300px]"
						/>
					</Link>
					<div className="flex flex-wrap justify-center items-center gap-4 lg:gap-8 font-medium">
						<p className="hover:text-troo-secondary transition-all cursor-pointer">
							Home
						</p>
						<p className="hover:text-troo-secondary transition-all cursor-pointer">
							About Us
						</p>
						<p className="hover:text-troo-secondary transition-all cursor-pointer">
							Our Services
						</p>
						<p className="hover:text-troo-secondary transition-all cursor-pointer">
							Contact Us
						</p>
					</div>
				</div>
				<div className="main-container w-full flex lg:flex-row flex-col lg:text-left text-center items-center justify-between gap-5 py-5">
					<p>
						Copyright © 2023.{" "}
						<span className="text-troo-secondary">TRooThemes</span>. All rights
						reserved.
					</p>
					<div className="flex flex-wrap justify-center items-center gap-4 lg:gap-6 text-sm">
						<p className="hover:text-troo-secondary transition-all cursor-pointer">
							Terms & Conditions
						</p>
						<p className="hover:text-troo-secondary transition-all cursor-pointer">
							Cookie Policy
						</p>
						<p className="hover:text-troo-secondary transition-all cursor-pointer">
							Privacy Policy
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
