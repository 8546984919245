import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import ReviewSection from "../sections/ReviewSection";
import PartnerSection from "../sections/PartnerSection";
import TeamImg from "../assets/images/team-2.png";
import Img2 from "../assets/images/service-details.png";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ProgressBar from "../components/ProgressBar";

const SingleTeam = () => {
	return (
		<Layout>
			<SubHeroSection title="Single Team" />
			<div className="relative bg-troo-light text-troo-tertiary w-full flex flex-col items-center">
				<div className="main-container-big w-full py-[100px] flex flex-col items-center gap-6">
					<div className="p-7 bg-troo-primary-light w-full grid grid-cols-1 md:grid-cols-3 items-center gap-4">
						<img
							src={TeamImg}
							className="w-full h-full object-cover"
							alt="TeamImg"
						/>
						<div className="md:col-span-2 flex flex-col items-start gap-5">
							<div className="w-full flex items-center justify-between gap-5 flex-wrap">
								<div className="flex flex-col items-start">
									<h3>Erik Wiliamson</h3>
									<p>CEO & Founder</p>
								</div>
								<div className="hidden 2xl:flex items-center gap-3">
									<p>Social</p>
									<div className="text-xl p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-tertiary bg-opacity-20 text-troo-tertiary hover:bg-troo-secondary transition-all">
										<FaFacebookF />
									</div>
									<div className="text-xl p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-tertiary bg-opacity-20 text-troo-tertiary hover:bg-troo-secondary transition-all">
										<FaXTwitter />
									</div>
									<div className="text-xl p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-tertiary bg-opacity-20 text-troo-tertiary hover:bg-troo-secondary transition-all">
										<FaLinkedinIn />
									</div>
									<div className="text-xl p-3 rounded-lg aspect-square flex items-center justify-center bg-troo-tertiary bg-opacity-20 text-troo-tertiary hover:bg-troo-secondary transition-all">
										<FaInstagram />
									</div>
								</div>
							</div>
							<div className="w-full h-[1px] bg-troo-tertiary bg-opacity-20 my-3" />
							<p className="text-2xl font-bold">About Erik Williamson</p>
							<p>
								There are many variations of passages of Lorem Ipsum available,
								but the majority have suffered alteration in some form, by
								injected humour, or randomised words which don't look even
								slightly believable. If you are going to use a passage of Lorem
								Ipsum, you need to be sure there isn't anything embarrassing
								hidden in the middle of text. All the Lorem Ipsum generators on
								the Internet tend to repeat predefined chunks as necessary
							</p>
							<p className="text-2xl font-bold mt-2">Contact Information</p>
							<div className="flex items-center flex-wrap gap-3">
								<p>
									Phone: <span className="font-bold">+820 123 456 7890</span>
								</p>
								<p>
									Email:{" "}
									<span className="font-bold">erilwilliamson@email.com</span>
								</p>
								<p>
									Location:{" "}
									<span className="font-bold">255,San Francisco, USA</span>
								</p>
							</div>
						</div>
					</div>
					<div className="my-4">
						<p className="text-2xl font-bold my-2">20 Years Experience</p>
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don't look even
							slightly believable. If you are going to use a passage of Lorem
							Ipsum, you need to be sure there isn't anything embarrassing
							hidden in the middle of text. All the Lorem Ipsum generators on
							the Internet tend to repeat predefined chunks as necessary
						</p>
					</div>
					<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
						<img src={Img2} alt="man" className="w-full h-full object-cover" />
						<div className="w-full flex flex-col items-start gap-3">
							<p className="text-2xl font-bold">Skill</p>
							<p>
								There are many variations of alteration passages of Lorem Ipsum
								available, but the majority have suffered alteration also in
								some form, by injected humour, or randomised words which don't
								look even slightly believable.
							</p>
							<div className="w-full flex flex-col items-start gap-3 mt-5">
								<p className="text-xl font-bold">Strategy</p>
								<ProgressBar percentage={40} />
							</div>
							<div className="w-full flex flex-col items-start gap-3">
								<p className="text-xl font-bold">Frontend</p>
								<ProgressBar percentage={80} />
							</div>
							<div className="w-full flex flex-col items-start gap-3">
								<p className="text-xl font-bold">Backend</p>
								<ProgressBar percentage={60} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<ReviewSection onlyForm />
			<PartnerSection />
		</Layout>
	);
};

export default SingleTeam;
