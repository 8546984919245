import React from "react";
import Heading from "../components/Heading";
import ServiceCard from "../components/ServiceCard";
import { SERVICES } from "../constants";

const ServiceSection = ({ isListingPage }) => {
	return (
		<div className="relative bg-troo-light bg-service-vector bg-center bg-contain bg-no-repeat text-troo-primary w-full flex flex-col items-center">
			<div className="main-container-big w-full py-[100px] flex flex-col items-center gap-10">
				{!isListingPage && (
					<Heading
						headline="What We Do Best"
						title={
							<>
								The Services <br /> We Provide With Perfection
							</>
						}
					/>
				)}
				<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
					{SERVICES.map((data, i) => {
						return <ServiceCard key={i} data={data} />;
					})}
				</div>
				{!isListingPage && (
					<div className="flex flex-col items-center rounded-lg p-4 border border-dashed border-troo-primary font-bold text-troo-tertiary text-base">
						<p>We Provide +4 More Services</p>
						<p>
							Want to see more?{" "}
							<span className="text-troo-secondary underline underline-offset-2">
								Click Here
							</span>
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default ServiceSection;
