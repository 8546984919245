import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import ReviewSection from "../sections/ReviewSection";
import PartnerSection from "../sections/PartnerSection";
import ServiceSection from "../sections/ServiceSection";

const Services = () => {
	return (
		<Layout>
			<SubHeroSection title="Our Services" />
			<ServiceSection isListingPage />
			<ReviewSection onlyForm />
			<PartnerSection />
		</Layout>
	);
};

export default Services;
