import React from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import PricingCardList from "./PricingCardList";
import { motion } from "framer-motion";

const PricingCard = ({ data }) => {
	return (
		<motion.div initial={{ y: 20, opacity: 0 }}
			whileInView={{ y: 0, opacity: 1 }}
			transition={{
				type: "spring",
				stiffness: 100,
			}} className="w-full bg-troo-primary-light text-troo-tertiary rounded-xl p-8 flex flex-col items-start gap-5">
			<p className="text-2xl font-bold">{data?.title}</p>
			<p>
				There are many variations of passages of Lorem Ipsum available, but the
				majority have suffered alteration
			</p>
			<div className="flex w-full items-center gap-2 p-5 rounded-lg bg-troo-secondary bg-opacity-10">
				<div className="flex items-start">
					<p>$</p>
					<p className="text-5xl font-bold">{data?.price}</p>
				</div>
				<p>
					Per User
					<br />
					Per Month
				</p>
			</div>
			<p className="text-xl font-bold">Including In this Plan</p>
			<div className="flex flex-col items-start gap-3 ml-4">
				<PricingCardList txt="Wiring for new constructed" />
				<PricingCardList txt="home/office/commercial space" />
				<PricingCardList txt="Home Renovation electrical work" />
				<PricingCardList txt="Fixing of Faulty wiring" />
				<PricingCardList txt="Replacement of damaged switches" />
				<PricingCardList txt="main control board, power points" />
			</div>
			<button className="btn-theme-secondary-outlined flex w-full justify-between mt-5">
				<span>Choose This Plan</span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth={1.5}
					stroke="currentColor"
					className="w-6 h-6"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
					/>
				</svg>
			</button>
		</motion.div>
	);
};

export default PricingCard;
