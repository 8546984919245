import React from "react";
import { IoFlashSharp } from "react-icons/io5";
import flashVector from "../assets/images/hero-flash-vector.svg";
import heroGirl from "../assets/images/hero-girl.png";
import { motion } from "framer-motion";

const HeroSection = () => {
	return (
		<div className="w-full bg-hero-back bg-cover bg-center bg-fixed overflow-hidden">
			<div className="relative bg-troo-primary bg-opacity-90 text-troo-light w-full flex flex-col items-center min-h-[800px] md:min-h-[919px] justify-center">
				<div className="main-container pb-[100px] sm:py-[80px] lg:py-0 w-full grid grid-cols-1 lg:grid-cols-2 gap-5 relative">
					<img
						src={heroGirl}
						alt="girl"
						className="absolute z-10 right-28 -bottom-28 xl:-bottom-28 object-contain hidden lg:block md:w-[500px] xl:w-[696px] h-[804px]"
					/>
					<motion.div
						initial={{ y: 20, opacity: 0 }}
						whileInView={{ y: 0, opacity: 1 }}
						transition={{
							type: "spring",
							stiffness: 100,
						}}
						className="flex lg:py-[120px] flex-col items-start gap-5"
					>
						<div className="flex items-center gap-3">
							<IoFlashSharp className="text-troo-secondary text-2xl" />
							<p className="text-sm sm:text-base md:text-lg lg:text-xl">
								Welcome to TRoo Electric Services
							</p>
						</div>
						<p className="text-3xl sm:text-4xl md:text-5xl lg:text-[70px] xl:leading-[90px] font-bold">
							Having The Best Solutions For Your Electrical Needs.
						</p>
						<p>
							It was popularised in the 1960s with the release of Letraset
							sheets containing Lorem Ipsum passages, and more recently with
							desktop publishing software like Aldus PageMaker including
							versions of Lorem Ipsum.
						</p>
						<div className="flex flex-wrap items-center gap-5">
							<button className="btn-theme-secondary hover:text-troo-light hover:border-troo-light flex">
								<span>View All Services</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
									/>
								</svg>
							</button>
							<button className="btn-theme-white hover:text-troo-light hover:border-troo-light flex">
								<span>Learn About Us</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
									/>
								</svg>
							</button>
						</div>
					</motion.div>
					<div className="w-full flex justify-center lg:justify-end relative">
						<img
							src={flashVector}
							alt="vector"
							className="w-1/2 sm:w-[60%] md:w-[70%] lg:w-[80%] object-contain"
						/>
					</div>
				</div>
				<img
					src={heroGirl}
					alt="girl"
					className="absolute left-1/2 -translate-x-1/2 bottom-0 object-contain block lg:hidden w-[70%] sm:w-[50%]"
				/>
			</div>
		</div>
	);
};

export default HeroSection;
