import React from "react";
import { IoCheckmarkDone } from "react-icons/io5";

const ServiceDetailsListItem = ({ txt }) => {
	return (
		<div className="flex items-center gap-2">
			<div>
				<IoCheckmarkDone className="text-xl" />
			</div>
			<p>{txt}</p>
		</div>
	);
};

export default ServiceDetailsListItem;
