import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import AboutSection from "../sections/AboutSection";
import StatisticsWorkSection from "../sections/StatisticsWorkSection";
import TeamSection from "../sections/TeamSection";
import WhyUsSection from "../sections/WhyUsSection";
import ReviewSection from "../sections/ReviewSection";
import PartnerSection from "../sections/PartnerSection";

const AboutUs = () => {
	return (
		<Layout>
			<SubHeroSection title="Learn About US" />
			<AboutSection />
			<StatisticsWorkSection />
			<TeamSection />
			<WhyUsSection />
			<ReviewSection />
			<PartnerSection />
		</Layout>
	);
};

export default AboutUs;
