import React from "react";
import Layout from "../components/Layout";
import SubHeroSection from "../components/SubHeroSection";
import TeamSection from "../sections/TeamSection";
import ReviewSection from "../sections/ReviewSection";
import PartnerSection from "../sections/PartnerSection";

const Teams = () => {
	return (
		<Layout>
			<SubHeroSection title="Meet Our Professional Team" />
			<TeamSection isListingPage />
			<ReviewSection onlyForm />
			<PartnerSection />
		</Layout>
	);
};

export default Teams;
