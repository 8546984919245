import React from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";

const PricingCardList = ({ txt }) => {
	return (
		<div className="flex items-center gap-2">
			<div>
				<BsFillPatchCheckFill className="text-xl" />
			</div>
			<p>{txt}</p>
		</div>
	);
};

export default PricingCardList;
