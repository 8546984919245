import React from "react";
import Heading from "../components/Heading";
import TeamCard from "../components/TeamCard";
import { TEAM } from "../constants";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const TeamSection = ({ isListingPage }) => {
	return (
		<div className="relative bg-troo-light text-troo-primary w-full flex flex-col items-center">
			<div className="main-container-big w-full py-[100px] flex flex-col items-center gap-10">
				{!isListingPage && (
					<div className="w-full flex items-center justify-between gap-6">
						<Heading
							isStart
							title={
								<>
									Let’s Meet with Our <br /> Expert Team Member
								</>
							}
							headline="What We Do Best"
						/>
						<div className="flex flex-col items-start gap-3">
							<p>
								There are many variations of passages they of Lorem Ipsum
								available,
								<br /> but the majority have suffered alteration also we in some
								form.
							</p>
							<Link
								to={ROUTES.ourTeam}
								className="p-4 hover:text-troo-secondary transition-all font-bold rounded-lg border border-dashed border-troo-tertiary text-troo-tertiary"
							>
								<span className="border-b">Meet Our All Member</span>
							</Link>
						</div>
					</div>
				)}
				<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
					{TEAM.map((data, i) => {
						return <TeamCard data={data} key={i} />;
					})}
				</div>
			</div>
		</div>
	);
};

export default TeamSection;
